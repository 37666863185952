/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(document).on('click', '.collapse.in .dropdown-toggle.closed', function (e) {
          e.preventDefault();
          $('.collapse.in li').removeClass('open');
          $('.collapse.in .dropdown-toggle').addClass('closed');
          
          $(this).removeClass('closed');
          $(this).parent().addClass('open');
          
        });
        
        $(".slider").slick({
          prevArrow: $('.carousel-wrapper .prev'),
          nextArrow: $('.carousel-wrapper .next'),
          
          responsive: [{
        
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                infinite: true
              }
        
            }, {
        
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                dots: false
              }
        
            }, {
        
              breakpoint: 300,
              settings: "unslick" // destroys slick
        
            }]
        });
      },
      finalize: function() {
        $('.match-height2').matchHeight();
        $('.match-height').matchHeight();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
